import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/internal/operators/map";

const baseUrl = environment.apiUrl + "/api";

@Injectable({
  providedIn: "root",
})
export class PracticesService {
  currentUser: any;
  constructor(public http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  // http://localhost:64164/api/Practice/GetAssociatedPractice/4
  // Get all Mohs practice list data
  getMohsPracticeListData(Id, caseId) {
    debugger;
    return this.http
      .get(
        `${baseUrl}/Practice/GetAssociatedPractice/` + Id + "?Case_Id=" + caseId
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getMohsPracticeListDatabyPatient(Id, patientId) {
    return this.http
      .get(
        `${baseUrl}/Practice/GetAssociatedPracticeByPatient/` + Id + "?Patient_Id=" + patientId
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get all Mohs practice list data
  getMohsPracticeListDataById(body) {
    return this.http
      .post(`${baseUrl}/Practice/GetAllMohPracticesByPractice`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get Staff List Base On Practice data
  getStaffPracticeListData(Id) {
    return this.http.get(`${baseUrl}/Practice/GetStaffsByPractice/` + Id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Get Practice Profile data
  getPracticeDetailListData(Id) {
    return this.http.get(`${baseUrl}/Practice/GetPractice/` + Id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Add Mohs practice
  createMohsPractice(data: any) {
    data["practiceType_Id"] = 1;
    return this.http.post(`${baseUrl}/Practice/CreatePractice`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getRefMohsPracticeListByData(body) {
    return this.http
      .post<any>(`${baseUrl}/Practice/ReftoMohsPracticeFilter`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getReferRequested(body) {
    debugger;
    return this.http
      .post<any>(`${baseUrl}/Practice/GetReferralRequest`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  GetSurgeryNotCompleted(Id){
  return this.http.get(`${baseUrl}/Case/GetSurgeryNotCompleted?CaseId=` + Id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Get search with id and name
  getMohsPracticeListByData(value) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    const body = {
      UserId: dataId.userid,
      Name: value,
    };
    return this.http
      .post<any>(`${baseUrl}/Practice/GetSearchMohPractices`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // Get all Mohs practice list data
  getPracticeProfileData() {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    return this.http
      .get(`${baseUrl}/Practice/GetPractice/` + dataId.userid)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get Staff list by Practice id
  getStaffDataByPracticeId() {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    return this.http
      .get(`${baseUrl}/Practice/GetStaffsByPractice/` + dataId.praticeid)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get Surgeon list by Practice id
  getSurgeonDataByPracticeId(id) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    return this.http.get(`${baseUrl}/Practice/GetStaffsByPractice/` + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // get all staff
  getStaff() {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    return this.http.get(`${baseUrl}/Practice/GetAllStaffs`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Get Staff list by Practice id
  getStaffeDataByPracticeId(data) {
    return this.http
      .get(`${baseUrl}/Practice/GetStaffsByPractice/` + data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // Create Staff
  createpractionarStaff(data) {
    return this.http.post(`${baseUrl}/Practice/CreateStaff`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Update Staff
  updatepractionarStaff(data) {
    return this.http.put(`${baseUrl}/Practice/PutStaff`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // http://localhost:64164/api/Practice/PracticeAssociatedWithMohPractices
  createAssociatePractice(data) {
    return this.http
      .post(`${baseUrl}/Practice/PracticeAssociatedWithMohPractices`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Update  staff
  updatePractionarStaff(data) {
    return this.http.put(`${baseUrl}/Practice/PutStaff`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // Update  practice
  updatePractice(data) {
    return this.http.put(`${baseUrl}/Practice/PutPractice`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  deleteStaff(data)
   {
    return this.http.get(`${baseUrl}/Practice/DeletePracticeStaff?staff_Id=` + data).pipe(
      map(res => {
        return res;
      })
    );
  }
}
