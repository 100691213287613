import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, CommonService } from "../../core/_services";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  currentUser: any;
  body: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  hide = true;
  confhide = true;
  isphoneverified = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public commonService: CommonService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue)
    {
      this.logout();
      this.router.navigate(["/login"]);
      //this.router.navigate(["/"]);
    }
  }
  logout() {
    if (this.currentUser.roleid == 1) {
      this.body = {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutAdmin(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/login"]);
        },
        error => {}
      );
    } else {
      this.body = {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutGeneral(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/login"]);
        },
        error => {}
      );
    }
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
    if (this.isphoneverified) {
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/twoFactorVerification";
    } else {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/active";
    }

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm;
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return
    }
    this.loading = true;
    this.submitted = true;
    this.authenticationService
      .login(this.f.get('email').value, this.f.get('password').value)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          this.commonService.getAllMenu(data.roleid).subscribe(
            (menuData: any) => {

              if (menuData) {
                console.log(menuData);
                const menuItems = menuData.result.map((item) => item.module);
                localStorage.setItem("menuItems", JSON.stringify(menuItems));
                const currentUser = data;
                localStorage.setItem(
                  "currentUser",
                  JSON.stringify(currentUser)
                );

                this.returnUrl =
                  this.route.snapshot.queryParams["returnUrl"] || "/twoFactorVerification";
                console.log(this.returnUrl);
                this.router.navigate([this.returnUrl]);
              }
            },
            (error) => {
              debugger;
              this.loading = false;
            }
          );
        },
        (error) => {

          this.toastr.error(error, "login error");
          this.error = error;
          this.loading = false;
        }
      );
  }



  redirectToLogin() {
    this.router.navigate(["/registration"]);
  }
}
