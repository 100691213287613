import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule, PreloadAllModules } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import {
  LocationStrategy,
  PathLocationStrategy,
  DatePipe,
} from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DemoMaterialModule } from "./demo-material-module";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { SharedModule } from "./shared/shared.module";
import { SpinnerComponent } from "./shared/spinner.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./component/login/login.component";
import { JwtInterceptor, ErrorInterceptor } from "./core/_helpers";
import { BreadcrumbModule } from "angular-crumbs";
import { AgmCoreModule } from "@agm/core";
import { CardModule } from "primeng/card";
import { DropdownModule } from "primeng/dropdown";
import { ModelComponent } from "./home/homeModel/model.component";
import { GenModelComponent } from "./home/genDermModel/model.component";
import { DermPathLabModelComponent } from "./home/dermPathLabModel/model.component";
import { from } from "rxjs";
import { InputMaskModule } from "primeng/inputmask";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { CheckboxModule } from "primeng/checkbox";
import { AdminloginComponent } from "./admin/login/adminlogin/adminlogin.component";
import { RegistrationComponent } from "./component/registration/registration.component";
import { CaptchaModule } from "primeng/captcha";
import { PasswordResetComponent } from "./admin/password-reset/password-reset.component";
import { ResetPasswordComponent } from "./component/password-reset/password-reset.component";
import { Ng4GeoautocompleteModule } from "ng4-geoautocomplete";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { RadioButtonModule } from "primeng/radiobutton";
import { AboutUsComponent } from "./about-us/about-us.component";
import { LocateCentersComponent } from "./locate-centers/locate-centers.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { TwoFactorVerificationComponent, FormatTimePipe } from './component/two-factor-verification/two-factor-verification.component';
import { FaqComponent } from './faq/faq.component';
import { PotentialPatientsComponent } from './potential-patients/potential-patients.component';
// import { HashLocationStrategy } from "@angular/common";



@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    LoginComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    HomeComponent,
    ModelComponent,
    GenModelComponent,
    DermPathLabModelComponent,
    AdminloginComponent,
    RegistrationComponent,
    PasswordResetComponent,
    ResetPasswordComponent,
    AboutUsComponent,
    LocateCentersComponent,
    NavBarComponent,
    ContactUsComponent,
    TwoFactorVerificationComponent,
    FormatTimePipe,
    FaqComponent,
    PotentialPatientsComponent,
  ],
  imports: [
    BrowserModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    DropdownModule,
    InputMaskModule,
    DynamicDialogModule,
    CheckboxModule,
    CaptchaModule,
    DialogModule,
    ConfirmDialogModule,
    RadioButtonModule,
    Ng4GeoautocompleteModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAY9zR0vI9AZfwer-vmRXJL-8goPJvOfuM",
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  entryComponents: [
    ModelComponent,
    GenModelComponent,
    DermPathLabModelComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
