import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GeocodeService } from "../core/_services/geocode.service";
import { STATES } from "../../assets/state";
import { DialogService } from "primeng/api";
import { ModelComponent } from "./homeModel/model.component";
import { GenModelComponent } from "./genDermModel/model.component";
import { DermPathLabModelComponent} from "./dermPathLabModel/model.component"

import { marker } from "./marker";
import { CommonService, AuthenticationService } from "../core/_services";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [DialogService],
})
export class HomeComponent implements OnInit {
  currentUser: any;
  address: any;
  location: any;
  stateData: any = STATES;
  loading: boolean;
  markers: any = [];
  zoomLevel = 4;
  infoWindowData: any;
  markrsData: any = [];
  previous: any;
  date: Date;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    public commonSerive: CommonService,
    public dialogService: DialogService
  ) {
    this.date = new Date();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getAllPracticesLocations();
  }

  redirectToLogin() {
    if (this.currentUser) {
      if (this.currentUser.rolename === "Admin") {
        this.router.navigate(["/admin/activeCase"]);
        this.toastr.success("You are already logged in");
      } else {
        this.router.navigate(["/active"]);
        this.toastr.success("You are already logged in");
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  redirectToAdmin() {
    if (this.currentUser) {
      if (this.currentUser.rolename === "Admin") {
        this.router.navigate(["/admin/activeCase"]);
        this.toastr.success("You are already logged in");
      } else {
        this.router.navigate(["/admin/login"]);
      }
    } else {
      this.router.navigate(["/admin/login"]);
    }
  }

  getAllPracticesLocations() {
    this.commonSerive.GetAllPratices().subscribe((data: any) => {
      if (data.result) {
        this.showLocation(data.result);
      }
    });
  }

  public showfileURL(fileURL) {
    ;
    window.open(fileURL, "_blank");
  }

  initialAddressToCoordinates() {
    if (this.address) {
      this.geocodeService
        .geocodeAddress(this.address.name)
        .subscribe((location: Location) => {
          this.zoomLevel = 6;
          this.location = location;
          this.ref.detectChanges();
        });
    } else {
      this.zoomLevel = 4;
      this.location = {
        lat: 39.381266,
        lng: -97.922211,
      };
    }
  }
  placeMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
  }
  clickedMarker(data, index: number, infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    ;
    this.previous = infowindow;
    this.infoWindowData = data;
  }

  async showLocation(locations) {
    ;
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].practiceType_Id) {
        let fullAddress;
        fullAddress = [locations[i].street_Address].filter(Boolean).join(", ");

        let fullAddress1;
        fullAddress1 = [
          locations[i].city,
          locations[i].state.stateCode,
          locations[i].zipCode,
        ]
          .filter(Boolean)
          .join(", ");
        let fullAddress2;
        fullAddress2 = [
          locations[i].street_Address,
          locations[i].city,
          locations[i].state.stateCode + " " + locations[i].zipCode,
        ]
          .filter(Boolean)
          .join(", ");
        const practice_Type = locations[i].practiceType_Id === 2 ? "G" : "M";
        // await this.addressToCoordinates(
        //   fullAddress.trim(),
        //   practice_Type,
        //   locations[i]
        // );
        const x = locations[i].phone
          .replace(/\D/g, "")
          .match(/(\d{3})(\d{3})(\d{4})/);
        locations[i].phone = "(" + x[1] + ") " + x[2] + "-" + x[3];
        this.markrsData.push({
          lat: locations[i].latitude,
          lng: locations[i].longitude,
          label: practice_Type,
          address: fullAddress.trim(),
          address1: fullAddress1.trim(),
          address2: fullAddress2.trim(),
          practice_Name: locations[i].name ? locations[i].name : "",
          phone: locations[i].phone,
          imgUrl:
            practice_Type === "G"
              ? "../assets/images/red.png"
              : "../assets/images/blue.png",
        });
      }
    }
    this.markers = this.markrsData;
    console.log(this.markers);
    this.location = {
      lat: 39.381266,
      lng: -97.922211,
    };
  }

  async addressToCoordinates(address, practice_Type, locations) {
    this.geocodeService.geocodeAddress(address).subscribe((location: any) => {
      this.markrsData.push({
        lat: location.lat,
        lng: location.lng,
        label: practice_Type,
        address: address,
        practice_Name: locations.name ? locations.name : "",
        phone: locations.phone,
        imgUrl:
          practice_Type === "G"
            ? "../assets/images/red.png"
            : "../assets/images/blue.png",
      });
      this.ref.detectChanges();
    });
  }

  onReset() {
    this.address = "";
    this.zoomLevel = 4;
    this.location = {
      lat: 39.381266,
      lng: -97.922211,
    };
  }

  show() {
    const ref = this.dialogService.open(ModelComponent, {
      header: "Mohs Practice Sign Up",
      // data: this.userProfileData
      // contentStyle: { 'max-height': '350px', 'overflow': 'auto' }
    });

    ref.onClose.subscribe((item) => {
      if (item) {
        this.toastr.success(
          "Please check your email Junk folder for an iTASC link to finish registration and to set your password.",
          "Registration successfully completed.",
          {
            timeOut: 900000,
          }
        );
      } else if (!item) {
      } else {
      }
    });
  }

  showGenModel() {
    const ref = this.dialogService.open(GenModelComponent, {
      header: "Referring Sign Up",
      // data: this.userProfileData
      // contentStyle: { 'max-height': '350px', 'overflow': 'auto' }
    });

    ref.onClose.subscribe((item) => {
      if (item) {
        this.toastr.success(
          "Please check your email Junk folder for an iTASC link to finish registration and to set your password.",
          "Registration successfully completed.",
          {
            timeOut: 900000,
          }
        );
        // "Please check your mailbox, you should receive an email regarding practice activation along with a password reset link, In case if you have not received it please ensure you also check spam folder",
      } else if (!item) {
      } else {
      }
    });
  }


  showDermPathLabModel() {
    const ref = this.dialogService.open(DermPathLabModelComponent, {
      header: "Derm Path Lab Sign Up",
      // data: this.userProfileData
      // contentStyle: { 'max-height': '350px', 'overflow': 'auto' }
    });

    ref.onClose.subscribe((item) => {
      if (item) {
        this.toastr.success(
          "",
          "Registration successfully completed.",
          {
            timeOut: 900000,
          }
        );
        // "Please check your mailbox, you should receive an email regarding practice activation along with a password reset link, In case if you have not received it please ensure you also check spam folder",
      } else if (!item) {
      } else {
      }
    });
  }
}
