import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { GeocodeService } from "../core/_services/geocode.service";
import { STATES } from "../../assets/state";

import { CommonService } from "../core/_services";
import { map } from "jquery";

@Component({
  selector: "app-locate-centers",
  templateUrl: "./locate-centers.component.html",
  styleUrls: ["./locate-centers.component.css"],
})
export class LocateCentersComponent implements OnInit {
  address: any = "";
  location: any;
  stateData: any = STATES;
  markers: any = [];
  zoomLevel = 4;
  infoWindowData: any;
  markrsData: any = [];
  previous: any;
  stateCenters: any = [];
  constructor(
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    public commonSerive: CommonService
  ) {}

  ngOnInit() {
    this.getAllPracticesLocations();

  }
  mainAccording = 1;
  toggleAccording(value: number): void {
    this.mainAccording = value+1;
  }

  getAllPracticesLocations() {
    this.commonSerive.GetAllPratices().subscribe((data: any) => {
      if (data.result) {
        this.showLocation(data.result);
      }
    });
  }

  findStatesWithCenters(){
    let firstOne: boolean;
    for(let i = 0; i < this.stateData.length; i++) {
      firstOne = false;
      for(let j = 0; j < this.markers.length; j++) {
        if(!firstOne && this.markers[j].address1.includes(this.stateData[i].abbreviation)) {
          firstOne = true;
          let name: string = this.stateData[i].name;
          let abbreviation: string = this.stateData[i].abbreviation;
          this.stateCenters.push({
            name,
            abbreviation
          });
        }
      }
    }

  }

  ZipCodeSearch(inputvalue:string){
    console.log("button clicked the value is: " + inputvalue);
    if(inputvalue) {
      this.geocodeService
        .geocodeAddress(inputvalue)
        .subscribe((location: Location) => {
          this.zoomLevel = 6;
          this.location = location;

          this.ref.detectChanges();
        });
    } else {
      this.zoomLevel = 4;
      this.location = {
        lat: 39.381266,
        lng: -97.922211,
      };
    }

  }

  initialAddressToCoordinates() {
    if (this.address) {
      this.geocodeService
        .geocodeAddress(this.address)
        .subscribe((location: Location) => {
          this.zoomLevel = 6;
          this.location = location;
          this.ref.detectChanges();
        });
    } else {
      this.zoomLevel = 4;
      this.location = {
        lat: 39.381266,
        lng: -97.922211,
      };
    }
  }
  placeMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
  }
  clickedMarker(data, index: number, infowindow) {
    if (this.previous) {
      this.previous.close();
    }

    this.previous = infowindow;
    this.infoWindowData = data;
  }

  async showLocation(locations) {

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].practiceType_Id == 2) {
        if (locations[i].isQualified == "Yes") {
          let fullAddress;
          fullAddress = [locations[i].street_Address]
            .filter(Boolean)
            .join(", ");

          let fullAddress1;
          fullAddress1 = [
            locations[i].city,
            locations[i].state.stateCode,
            locations[i].zipCode,
          ]
            .filter(Boolean)
            .join(", ");
          let fullAddress2;
          fullAddress2 = [
            locations[i].street_Address,
            locations[i].city,
            locations[i].state.stateCode + " " + locations[i].zipCode,
          ]
            .filter(Boolean)
            .join(", ");
          var practice_Type;
          if (locations[i].practiceType_Id === 2) {
            practice_Type = "G";
          } else {
            practice_Type = "M";
          }
          // } else if (locations[i].practiceType_Id === 1) {
          //   practice_Type = "M";
          // } else if (locations[i].practiceType_Id === 3) {
          //   practice_Type = "D";
          // }
          // const practice_Type = locations[i].practiceType_Id === 2 ? "G" : "M";
          // await this.addressToCoordinates(
          //   fullAddress.trim(),
          //   practice_Type,
          //   locations[i]
          // );
          const x = locations[i].phone
            .replace(/\D/g, "")
            .match(/(\d{3})(\d{3})(\d{4})/);
          locations[i].phone = "(" + x[1] + ") " + x[2] + "-" + x[3];
          this.markrsData.push({
            lat: locations[i].latitude,
            lng: locations[i].longitude,
            label: practice_Type,
            address: fullAddress.trim(),
            address1: fullAddress1.trim(),
            address2: fullAddress2.trim(),
            practice_Name: locations[i].name ? locations[i].name : "",
            phone: locations[i].phone,
            imgUrl:
              practice_Type === "G"
                ? "../assets/images/red.png"
                : practice_Type === "M"
                ? "../assets/images/blue.png"
                : "../assets/images/green.png",
          });
        }
      } else {
        if (locations[i].practiceType_Id) {
          let fullAddress;
          fullAddress = [locations[i].street_Address]
            .filter(Boolean)
            .join(", ");

          let fullAddress1;
          fullAddress1 = [
            locations[i].city,
            locations[i].state.stateCode,
            locations[i].zipCode,
          ]
            .filter(Boolean)
            .join(", ");
          let fullAddress2;
          fullAddress2 = [
            locations[i].street_Address,
            locations[i].city,
            locations[i].state.stateCode + " " + locations[i].zipCode,
          ]
            .filter(Boolean)
            .join(", ");
          var practice_Type;
          if (locations[i].practiceType_Id === 2) {
            practice_Type = "G";
          } else {
            practice_Type = "M";
          }
          // } else if (locations[i].practiceType_Id === 1) {
          //   practice_Type = "M";
          // } else if (locations[i].practiceType_Id === 3) {
          //   practice_Type = "D";
          // }
          // const practice_Type = locations[i].practiceType_Id === 2 ? "G" : "M";
          // await this.addressToCoordinates(
          //   fullAddress.trim(),
          //   practice_Type,
          //   locations[i]
          // );
          const x = locations[i].phone
            .replace(/\D/g, "")
            .match(/(\d{3})(\d{3})(\d{4})/);
          locations[i].phone = "(" + x[1] + ") " + x[2] + "-" + x[3];
          this.markrsData.push({
            lat: locations[i].latitude,
            lng: locations[i].longitude,
            label: practice_Type,
            address: fullAddress.trim(),
            address1: fullAddress1.trim(),
            address2: fullAddress2.trim(),
            practice_Name: locations[i].name ? locations[i].name : "",
            phone: locations[i].phone,
            imgUrl:
              practice_Type === "G"
                ? "../assets/images/red.png"
                : practice_Type === "M"
                ? "../assets/images/blue.png"
                : "../assets/images/green.png",
          });
        }
      }
    }
    // practice_Type === "G"
    // ? "../assets/images/red.png"
    // : "../assets/images/blue.png",
    // this.markers = this.markrsData;
    // console.log(this.markers);


    this.markers =  this.markrsData.filter(function(m) {
      return m.label !=='D'
    });
    console.log(this.markers);









    this.findStatesWithCenters();

    this.location = {
      lat: 39.381266,
      lng: -97.922211,
    };
  }

  async addressToCoordinates(address, practice_Type, locations) {
    this.geocodeService.geocodeAddress(address).subscribe((location: any) => {
      this.markrsData.push({
        lat: location.lat,
        lng: location.lng,
        label: practice_Type,
        address: address,
        practice_Name: locations.name ? locations.name : "",
        phone: locations.phone,
        imgUrl:
          practice_Type === "G"
            ? "../assets/images/red.png"
            : practice_Type === "M"
            ? "../assets/images/blue.png"
            : "../assets/images/green.png",
      });
      this.ref.detectChanges();
    });
  }

  onReset() {
    this.address = "";
    this.zoomLevel = 4;
    this.location = {
      lat: 39.381266,
      lng: -97.922211,
    };
  }
}
