import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient) {}

  // Get All Menu Item By Role ID
  getAllMenu(Id: any) {
    if (Id === "1") {
      return this.http
        .get(`${environment.adminUrl}/api/RoleModuleMap/GetMenuByRoleId/` + Id)
        .pipe(
          map((res) => {
            return res;
          })
        );
    } else {
      return this.http
        .get(`${environment.apiUrl}/api/RoleModuleMap/GetMenuByRoleId/` + Id)
        .pipe(
          map((res) => {
            return res;
          })
        );
    }
  }

  // Get All Position
  getAllPosition() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllPosition`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All State
  getAllStates() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllStates`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All State
  GetAllPrivileges() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllPrivileges`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get User Profile Data By Id
  getUserProfileData() {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    return this.http
      .get(`${environment.apiUrl}/api/User/GetUser/` + dataId.userid)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All Patient Name
  getAllPatientName() {
    return this.http
      .get(`${environment.apiUrl}/api/Patient/GetAllPatients`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All Staff Name
  getAllStaffName() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllStaffs`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All Lesion
  getAllLesion() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllLesion`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // http://192.168.2.60:200/api/Pratice/GetAllPratices
  // Get All Lesion Type
  getAllLesionType() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllLesionType`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // http://192.168.2.60:200/api/Practice/GetAllPractices

  // Get All Practice Type
  getAllPracticeTypes() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllPracticeTypes`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // http://localhost:64164/api/Practice/GetAllPrivileges
  // Get All Privileges Type
  getAllPrivilegesTypes() {
    return this.http
      .get(`${environment.apiUrl}/api/Practice/GetAllPrivileges`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All Practice Type
  getMohsStatusAction() {
    return this.http
      .get(`${environment.apiUrl}/api/Case/GetMohsStatusAction`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get All Practice Type
  getGeneralStatusAction() {
    return this.http
      .get(`${environment.apiUrl}/api/Case/GetGeneralStatusAction`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Update  Profile
  updateProfile(data) {
    return this.http
      .put(`${environment.apiUrl}/api/User/PutUpdateUser`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Get Check list
  getAllCheckList(body) {
    return this.http
      .post(`${environment.apiUrl}/api/Patient/GetChecklist`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get Download check list
  getFile(Id: any) {
    return this.http
      .get(`${environment.apiUrl}/api/Case/DownloadChecklist/` + Id)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  GetAllPratices() {
    return this.http
      .get<any>(`${environment.apiUrl}/api/Practice/GetAllPractices`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  registerUser(body) {
    return this.http
      .post(`${environment.apiUrl}/api/Registration/CreateRegistration`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //localhost:64164/api/User/SendEmailToRSVP
  // Send mail rsvp
  sendMailRsvp(body) {
    return this.http
      .post(`${environment.apiUrl}/api/User/SendEmailNewUser`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // send mail for reset password
  sendMailResetPassword(body) {
    return this.http
      .post(`${environment.apiUrl}/api/User/SendEmailResetPassword`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  sendMailChangePassword(body) {
    return this.http
      .post(`${environment.apiUrl}/api/User/ChangePasswordEmail`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // Update  Password
  ResetPassword(data) {
    return this.http
      .put(`${environment.apiUrl}/api/User/updatePassword`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // POST /api/Account/LogOutLogging
  //logout for general
  logoutGeneral(body) {
    debugger;
    return this.http
      .post(
        `${environment.apiUrl}/api/Account/LogOutLogging?` +
          "UserId=" +
          body.UserId +
          "&UserName=" +
          body.UserName,
        null
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // POST /api/Account/LogOutLogging
  //logout for general
  logoutAdmin(body) {
    debugger;
    return this.http
      .post(
        `${environment.adminUrl}/api/Account/LogOutLogging?` +
          "UserId=" +
          body.UserId +
          "&UserName=" +
          body.UserName,
        body
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //logging pageurl
  loggingAllPageUser(body) {
    return this.http
      .post(`${environment.apiUrl}/api/Account/InsertApiLog`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // mohs surgeon signup
  mohsSurgeonSignup(body) {
    body["practiceType_Id"] = 1;
    return this.http
      .post(`${environment.apiUrl}/api/Practice/CreateMohsPractice`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // genderm surgeon signup
  genDermSignup(body) {
    body["practiceType_Id"] = 2;
    return this.http
      .post(`${environment.apiUrl}/api/Practice/CreateMohsPractice`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  addPracticeLocation(body) {
    return this.http
      .post(`${environment.apiUrl}/api/Practice/AddPracticeAddresses`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // dermPathLab surgeon signup
  dermPathLabSignup(body) {
    body["practiceType_Id"] = 3;
    return this.http
      .post(`${environment.apiUrl}/api/Practice/CreateMohsPractice`, body)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Add new position
  addNewPosition(data) {
    return this.http
      .put(`${environment.apiUrl}/api/Practice/PutUpdateUser`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

}


