import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ActiveCaseDetailsComponent } from "../component/active-cases/active-case-details/active-case-details.component";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  currentUser: any;
  status: string;
  isphoneverified = false;
  checkMobile = false;
  constructor(private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  redirctToHome() {
    this.router.navigate(["../home"]);
  }

  // redirectToAbout() {
  //   this.router.navigate(["../about-us"]);
  // }

  // redirectToMap() {
  //   this.router.navigate(["../locate-centers"]);
  // }

  // redirectToContact() {
  //   this.router.navigate(["../contact-us"]);
  // }

  redirectToLogin() {
    debugger;
    //this.router.navigate(["/login"]);
    if (this.currentUser) {
      if (this.currentUser.rolename === "Admin") {
        this.router.navigate(["/admin/activeCase"]);
        this.toastr.success("You are already logged in");
      } else {
        if (this.isphoneverified) {
          this.router.navigate(["/active"]);
          this.toastr.success("You are already logged in");

        }
        else
        {
          this.router.navigate(["/login"]);
          //this.router.navigate(["/twoFactorVerification"]);
          // this.toastr.success("You are already logged in but need to verify your phone number");
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
