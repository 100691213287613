import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

const baseUrl = environment.adminUrl + "/api";

@Injectable({
  providedIn: "root",
})
export class dataAccessLogService {
  constructor(private http: HttpClient) {}

  //   http://192.168.2.60:260/api/User/GetAllHistoryTables
  // Get All History Details
  getAllHistory(pageIndex, pageSize, isOrderByASC, orderByColumn) {
    debugger;
    //  ?patientsName=James&page=1&pageSize=10
    return this.http
      .get(
        `${baseUrl}/Case/GetAllCases?page=${pageIndex}&pageSize=${pageSize}&ascending=true&orderBy=Id`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllHistoryfilter(pageIndex, pageSize, isOrderByASC, value) {
    debugger;
    //  ?patientsName=James&page=1&pageSize=10
    return this.http
      .get(
        `${baseUrl}/Case/GetAllCases?page=${pageIndex}&pageSize=${pageSize}&ascending=true&orderBy=Id&filter=${value}`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Case/GetCasesByPatientName?patientsName=${pageIndex}&page=${pageIndex}&pageSize=${pageSize}
  //   Case/GetAllCases?page=1&pageSize=10&ascending=true&orderBy=Id&filter=James

  //   http://192.168.2.60:260/api/User/HistoryTableRecords/1
  // Get All Case History Details
  getAllCaseHistory() {
    return this.http.get(`${baseUrl}/User/HistoryTableRecords/1`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //   http://192.168.2.60:260/api/User/GetAllHistoryTables
  // Get All case document History Details
  getAllCaseDocHistory() {
    return this.http.get(`${baseUrl}/User/HistoryTableRecords/2`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // view All History Details   GET /api/Case/GetAllCaseHistory
  viewAllHistory(pageIndex, pageSize, isOrderByASC, orderByColumn) {
    return this.http
      .get(
        `${baseUrl}/Case/GetAllCaseHistory?page=${pageIndex}&pageSize=${pageSize}&ascending=${isOrderByASC}&orderBy=${orderByColumn}`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
