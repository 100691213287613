import { Injectable } from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { of } from "rxjs/observable/of";
import { filter, catchError, tap, map, switchMap } from "rxjs/operators";
import { fromPromise } from "rxjs/observable/fromPromise";
import { Location } from "../_models/location-model";
export type Maps = typeof google.maps;
declare var google: any;

@Injectable({ providedIn: "root" })
export class GeocodeService {
  private geocoder: any;

  constructor(private mapLoader: MapsAPILoader) {}

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return fromPromise(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  geocodeAddress(location: string) {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  public readonly api = this.load();

  private load(): Promise<Maps> {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    // tslint:disable-next-line:no-bitwise
    const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
    script.src = this.getScriptSrc(callbackName);

    interface MyWindow {
      [name: string]: Function;
    }
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = resolve;
      script.onerror = reject;
    });
    document.body.appendChild(script);
    return promise.then(() => google.maps);
  }

  private getScriptSrc(callback: string): string {
    interface QueryParams {
      [key: string]: string;
    }
    const query: QueryParams = {
      v: "3",
      callback,
      key: "AIzaSyAY9zR0vI9AZfwer-vmRXJL-8goPJvOfuM",
      libraries: "places",
    };
    const params = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");
    return ;
    /* `//maps.googleapis.com/maps/api/js?${params}&language=en-US` */ 
  }
}
