import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../config/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private http: HttpClient) {}

  // Get All Menu Item By Role ID
  getAllMenu(Id: any) {
    return this.http
      .get(`${environment.adminUrl}/api/RoleModuleMap/GetMenuByRoleId/` + Id)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        })
      );
  }

  // Get All Position
  getAllPosition() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllPosition`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All State
  getAllStates() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllStates`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All State
  GetAllPrivileges() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllPrivileges`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get User Profile Data By Id
  getUserProfileData() {
    const dataId = JSON.parse(localStorage.getItem('currentUser'));
    return this.http
      .get(`${environment.adminUrl}/api/User/GetUser/` + dataId.userid)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Patient Name
  getAllPatientName() {
    return this.http
      .get(`${environment.adminUrl}/api/Patient/GetAllPatients`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Staff Name
  getAllStaffName() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllStaffs`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Lesion
  getAllLesion() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllLesion`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Lesion Type
  getAllLesionType() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllLesionType`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Practice Type
  getAllPracticeTypes() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllPracticeTypes`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  GetAllPratices() {
    return this.http
      .get<any>(`${environment.adminUrl}/api/Practice/GetAllPracticeData`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // http://localhost:64164/api/Practice/GetAllPrivileges
  // Get All Privileges Type
  getAllPrivilegesTypes() {
    return this.http
      .get(`${environment.adminUrl}/api/Practice/GetAllPrivileges`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Practice Type
  getMohsStatusAction() {
    return this.http
      .get(`${environment.adminUrl}/api/Case/GetMohsStatusAction`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All Practice Type
  getGeneralStatusAction() {
    return this.http
      .get(`${environment.adminUrl}/api/Case/GetGeneralStatusAction`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Update  Profile
  updateProfile(data) {
    return this.http
      .put(`${environment.adminUrl}/api/User/PutUpdateUser`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get Check list
  getAllCheckList(body) {
    return this.http
      .post(`${environment.adminUrl}/api/Patient/GetChecklist`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // get Download check list
  getFile(Id: any) {
    return this.http
      .get(`${environment.adminUrl}/api/Case/DownloadChecklist/` + Id)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  // localhost:64164/api/User/SendEmailToRSVP
  // Send mail rsvp
  sendMailRsvp(body) {
    return this.http
      .post(`${environment.adminUrl}/api/User/SendEmailToRSVP`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sendMailResetPassword(body) {
    return this.http
      .post(`${environment.adminUrl}/api/User/SendEmailResetPassword`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Update  Profile
  ResetPassword(data) {
    return this.http
      .put(`${environment.adminUrl}/api/User/updatePassword`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // register user
  registerUser(body) {
    return this.http
      .post(`${environment.adminUrl}/api/Registration/CreateRegistration`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get All User acitvity log list 
  getAllUserActivityLog() {
    return this.http
      .get(`${environment.adminUrl}/api/User/GetUserActivityLog`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // POST /api/Account/LogOutLogging
  //logout for general
  logoutAdmin(body) {
    debugger;
    return this.http
      .post(
        `${environment.adminUrl}/api/Account/LogOutLogging?` +
          "UserId=" +
          body.UserId +
          "&UserName=" +
          body.UserName,
        body
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
