import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor() { }

  ngOnInit() {
  }
  helpAccording = 1;
  mainAccording = 1;
  toggleHelpAccording(value: number): void {
    this.helpAccording = value;
  }
  toggleAccording(value: number): void {
    this.mainAccording = value;
  }
  ngAfterViewInit() {
    // Bootstrap Collapse logic goes here after the view has been initialized


    // const panelHeadingLinks = document.querySelectorAll("#accordion .panel-heading a");

    // panelHeadingLinks.forEach((link) => {
    //   link.addEventListener('click', (event: Event) => {
    //     const panel = (link as HTMLElement).closest('.panel') as HTMLElement;
    //     const panelCollapse = panel.querySelector('.panel-collapse') as HTMLElement;

    //     if (panelCollapse.classList.contains('show')) {
    //       const target = event.target as HTMLElement;
    //       const panelHeading = target.previousElementSibling as HTMLElement;
    //       panelHeading.classList.remove('active');
    //       event.preventDefault();
    //       event.stopPropagation();
    //     }else{
    //       const target = event.target as HTMLElement;
    //       const panelHeading = target.previousElementSibling as HTMLElement;
    //       panelCollapse.classList.add('show')
    //       panelHeading.classList.add('active');

    //     }
    //     // const accordion = document.querySelector("#accordion") as HTMLElement;

    //     // accordion.addEventListener('show.bs.collapse', (event: Event) => {

    //     // });

    //     // accordion.addEventListener('hide.bs.collapse', (event: Event) => {

    //     // });
    //   });
    // });
  }

  ngOnDestroy() {
    // Cleanup logic goes here when the component is destroyed
  }
}
