import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  getSanitizedHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
  ngOnInit() {
  }
  mainAccording = 1;
  toggleAccording(value: number): void {
    this.mainAccording = value+1;
  }

  faqList = [
    {
      title: "How does a patient with a suspicious lesion for skin cancer get referred to iTASC?",
      description: ``
    },
    {
      title: "How are patients qualified?",
      description: `<ol type="a">
      <li>We are requiring that the referring physician’s office verify whether the patient falls at or below 150% of the Federal Poverty Level based on household size before registering the patient on iTASC.</li>
      <li>Only patients that have skin cancer that meet the Mohs AUC criteria with a 7, 8 or 9 score will be treated through iTASC. </li>
    </ol>`
    },
    {
      title: "How are physicians identified and qualified?",
      description: `<ol type="a">
      <li>All physicians must be board certified and carry an active medical license. This will be verified by iTASC administration at the Federation of State Medical Boards (FSMB) website. This website also lists physician education and whether there have been any actions against the enrolling physician.</li>
      <li>If they are Mohs surgeons volunteering their services, they must also be members of the ACMS.</li>
    </ol>`
    },
    {
      title: "How do I register?",
      description: `<p> Registration is available directly on the homepage of
      <a href="http://itasc.org/" target="_blank">www.itasc.org</a> via the Mohs, Referring and Pathology Buttons.</p>`
    },
    {
      title: "As a volunteer Mohs surgeon, how can I specify the number of referrals per month?",
      description: `<p>Under the Mohs Practice Admin Login, the administrative assistant will enter the number of max referrals per month for the practice specified by the surgeons participating.</p>`
    },
    {
      title: "What data entry is required from the physicians?",
      description: `From the Referring Physician:
      <ul>
        <li>Patient Details: Name, Address, DOB, Phone, Email, Verify patient’s poverty level</li>
        <li>Tumor Details: Dx, Location, Bx Date, Meets "Appropriate Use Criteria"</li>
        <li>Referral Checklist: Path report, Clinic note, Image of lesion</li>
      </ul>
      From the Mohs Surgeon:
      <ol>
        <li>Surgery Completed vs. Not Completed</li>
        <li>Surgery Note</li>
        <li>Surgery Pics</li>
        <li>High Risk SCC Characteristics: Diameter>2cm, PNI > or equal to 0.1mm, Poor diff, Past fat</li>
        <li>Discharge Plan: Needs Further Imaging, Topical Rx for Pre-Cancer, Topical Rx for Superficial Cancer, Staged Excision, Rad Onc Referral, Surg Onc Referral, Med Onc Referral, Plastic Sx Referral, None</li>
      </ol>`
    },
    {
      title: "How is the data protected?",
      description: `<ol type="a">
      <li>Secure Site Designation for the website</li>
      <li>HIPAA Compliant Server</li>
      <li>Two-factor authentication</li>
      <li>Audit log that shows who accessed or updated a chart</li>
    </ol>`
    },
    {
      title: "What if there is additional work-up or treatment needed before surgery?",
      description: ` Under Mohs Login/Active Cases/ Take Action: Check a box for 1. Needs Further Imaging 2. Specialist Referral Made with a free text field 3. Other Plan with a free text field 4. Patient cancelled or did not show for appointment.`
    },
    {
      title: "What happens if the patient doesn’t show for surgery?",
      description: ` Under Mohs Login /Active Cases/ Take Action/ There will be an action box for surgery not completed. A pop-up box will show under the status column with an option to check patient cancelled appointment or no showed. At this point it would be up to the Mohs surgeon to send a life letter to the patient explaining the risks of not having the cancer treated.`
    },
    {
      title: "Are there any disclaimers?",
      description: `<p>iTASC is not a safety net. Our website states that we are expanding services as fast as possible, but every referring physician is still responsible to make sure that his/her patient receives the care that is needed in the appropriate time frame. This disclaimer must be signed by every physician who registers and every time a case is added or accepted from the platform.</p>`
    },


  ]
}
