import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../core/_services";
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  ContactUs: FormGroup;
  submitted = false;
  display = false;
  constructor
  (
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private userService: UserService
    ) { }

    openDisclaimer(e) {
      debugger;
      this.submitted = true;

      // stop here if form is invalid
      if (this.ContactUs.invalid) {
        return;
      } else {
        this.showDialog(e);
      }
    }

    showDialog(e) {
      debugger;
      this.display = true;
    }

    onDialogClose(event) {
      debugger;
      this.display = event;
      this.submitted = false;
    }
    onReset() {
      this.submitted = false;
      this.ContactUs.reset();
    }
  ngOnInit()
  {
    this.ContactUs = this.formBuilder.group({
      Name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      PhoneNumber: ["", [Validators.required]],
      Message: ["", [Validators.required]]
    });
  }

  get f() {
    return this.ContactUs;
  }
  onSubmit(e)
  {
    debugger
    if (this.ContactUs.invalid) {
      this.ContactUs.markAllAsTouched();
      return
    }
    if (this.ContactUs.invalid)
    {
      this.toastr.error(
        "All fields are  required!!",
        "Alert"
      );
      return;
    }
    this.toastr.warning(
      "Sending Email",
      "Please Wait"
    );
    (<HTMLInputElement>document.getElementById("btnSubmit")).disabled = true;
    this.userService.SendContactUsEmail(this.ContactUs.controls["Name"].value,this.ContactUs.controls["email"].value,this.ContactUs.controls["PhoneNumber"].value,this.ContactUs.controls["Message"].value,).subscribe((data: any) => {
      (<HTMLInputElement>document.getElementById("btnSubmit")).disabled = false;
      if (data)
      {
        this.toastr.success(
          "Email sent successfully sent"
        );
        this.ContactUs.reset();
      }
    });
  }

}
