import { Component } from "@angular/core";
import { AuthenticationService } from "../../../core/_services/authentication.service";
import { Router } from "@angular/router";
import { CommonService } from "../../../core/_services/common.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styles: [
    ` button:focus{
      outline: none !important;
    }`
  ]
})
export class AppHeaderComponent {
  menuOpen: boolean;
  currentUser: any;
  body: any;
  public href: string = "";
  public displayStyle : string = "none";
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,

  )
  {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  change_admin_email()
  {
    // this.router.navigate(['/root/parent/../reservationdetail']);
    this.router.navigate(["admin/UpdateEmailForAdmin"]);
  }
  logout() {
    if (this.currentUser.roleid == 1)
    {
      this.body =
      {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutAdmin(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/"]);
        },
        error => {}
      );
    } else {
      this.body = {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutGeneral(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/"]);
        },
        error => {}
      );
    }
  }
  changePassword()
  {
    this.body =
    {
      fullName:this.currentUser.fullName,
      userName: this.currentUser.username,
      baseUrl :"",
      id : this.currentUser.userid
    }
    this.commonService.sendMailChangePassword(this.body).subscribe(
      (data) =>
      {
        this.toastr.success(
          "Please check your email Junk folder for an iTASC link to change your password.",
          "Email Sent",
          {
            timeOut: 100000,
          });
      },
      (error) => {
        this.toastr.error(
          "Unableto send email for link to change password.",
          "Error",
          {
            timeOut: 1000,
          });
      }
    );
  }
}
