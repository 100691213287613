import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
    constructor(private el: ElementRef, private router: Router) { }
    @HostListener('click', ['$event'])
    public onClick(event) {
        if (event.target.tagName === 'A') {
            if (event.target.getAttribute('href')) {
                const navigateUrl = event.target.getAttribute('href').split('?');
                const currentUser = JSON.parse(localStorage.getItem('currentUser'));
                if (currentUser.rolename === 'Admin') {
                    this.router.navigate([
                        '/admin/mohs-practice/details/'],
                        { queryParams: { practiceId: navigateUrl[1] } }
                    );
                } else {
                    this.router.navigate([
                        navigateUrl[0]],
                        { queryParams: { practiceId: navigateUrl[1] } }
                    );
                }
                event.preventDefault();
            } else {
                return;
            }
        } else {
            return;
        }
    }
}
