import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

const baseUrl = environment.adminUrl + "/api";
const baseUrlLocal = environment.apiUrl + "/api";
@Injectable({
  providedIn: "root",
})
export class ActiveCaseService {
  constructor(private http: HttpClient) {}
  // http://192.168.2.60:260/api/Case/GetActiveCases
  // Get all ActiveCase list data
  getActiveCaseListData() {
    return this.http.get(`${baseUrl}/Case/GetActiveCases`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Get All Action Details
  getAllAction() {
    return this.http.get(`${baseUrl}/Practice/GetAllStatus`).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // Add Active cases
  createActiveCases(data: any) {
    return this.http.post(`${baseUrl}/Case/CreateCase`, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // Get Active cases by Id
  getActiveCaseByID(data: any) {
    return this.http.get(`${baseUrl}/Case/GetActiveCaseById/` + data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  changeInCaseStatus(body) {
    return this.http.post(`${baseUrl}/Case/TakeActionforCases`, body).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postFileUpload(formData) {
    return this.http.post(`${baseUrl}/case/UploadFile`, formData).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //  http://localhost:64164/api/Case/UpdateCheckList
  patientPostFileUpload(formData) {
    return this.http.post(`${baseUrlLocal}/Case/UpdateCheckList`, formData).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //http://localhost:64164/api/Case/IsTumorExist/4
  //get all tumor check list
  getTumorCheckList(data: any) {
    let currentuser = JSON.parse(localStorage.getItem("currentUser"));
    let body = {
      lesion_Id: data,
      role_Id: currentuser.roleid,
    };
    return this.http.post(`${baseUrl}/Case/IsTumorExist`, body).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //  Document Delete
  patientFileDelete(Id) {
    debugger;
    let currentuser = JSON.parse(localStorage.getItem("currentUser"));
    let body = {
      doc_id: Id,
      user_id: currentuser.userid,
    };
    return this.http.post(`${baseUrlLocal}/Case/DeleteDocument`, body).pipe(
      map((res) => {
        console.log(res);
        return res;
      })
    );
  }

  // Insert case history http://localhost:64165/api/Case/InsertCaseNotes
  viewCaseHistory(body) {
    debugger;
    return this.http.post(`${baseUrl}/Case/InsertCaseNotes`, body).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Get all ActiveCase list data
  getOtherCaseListData() {
    return this.http.get(`${baseUrl}/Case/GetOtherCases`).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
