import { Component, OnInit,HostListener } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { Title } from "@angular/platform-browser";
import { Breadcrumb, BreadcrumbService } from "angular-crumbs";
import { Subject } from 'rxjs';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  Event,
  NavigationError,
} from "@angular/router";
import { CommonService } from "./core/_services/common.service";
import { AuthenticationService } from "./core/_services/authentication.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  userData: any;
  currentUser: any;
  userActivity;
  userInactive: Subject<any> = new Subject();
  public href: string = "";
  body: any;
  constructor(
    private authenticationService: AuthenticationService,
    public matIconRegistry: MatIconRegistry,
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setTimeout();
    this.userInactive.subscribe(() => 
    this.logout()
    );
    matIconRegistry.registerFontClassAlias("fas");
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        var userData = JSON.parse(localStorage.getItem("currentUser"));
        if (userData) {
          this.body = {
            userId: userData.userid,
            name: userData.fullName,
            email:userData.username,
            praticename:userData.praticename,
            token: userData.access_token,
            pageUrl: window.location.href,
          };
          this.commonService.loggingAllPageUser(this.body).subscribe(
            (data) => {},
            (error) => {}
          );
        }
      }
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
    //this.userActivity = setTimeout(() => this.userInactive.next(undefined), 30000);
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  
  ngOnInit() {
    this.breadcrumbService.breadcrumbChanged.subscribe((crumbs) => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = "iTasc/";
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) {
      return "iTasc";
    }

    const routeTitle = this.titlesToString(titles);
    return `${title + routeTitle}`;
  }
  logout() {
    if (this.currentUser.roleid == 1) {
      this.body = {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutAdmin(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/"]);
        },
        error => {}
      );
    } else {
      this.body = {
        UserId: this.currentUser.userid,
        UserName: this.currentUser.username
      };

      this.commonService.logoutGeneral(this.body).subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(["/"]);
        },
        error => {}
      );
    }
  }
  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName}`;
    }, "");
  }
}
