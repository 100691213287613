import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, CommonService } from "../../core/_services";
import { UserService } from "../../core/_adminServices/user.services";
import { STATES } from "../../../assets/state";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {
  signupForm: FormGroup;
  signupMForm: FormGroup;
  samendx: any = 0;
  loading = false;
  submitted = false;
  returnUrl: string;
  stateData: any = STATES;
  error = "";
  dataSource = [];
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public commonService: CommonService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      username: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      city: ["", Validators.required],
      stateId: ["", Validators.required],
    });

    this.signupMForm = this.formBuilder.group({
      username: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      city: ["", Validators.required],
      stateId: ["", Validators.required],
      noOfCase: ["", Validators.required],
    });
  }
  showResponse(event) {}
  onTabChanged(event) {
    debugger;
    this.samendx = event;
  }
  get f() {
    return this.signupForm;
  }
  get m() {
    return this.signupMForm;
  }

  onSubmit() {
    debugger;

    let body: any = {};
    this.submitted = true;
    if (this.samendx == 0) {
      if (this.signupForm.invalid) {
        return;
      }
      body = this.signupForm.value;
      body.position = 1;
    } else {
      if (this.signupMForm.invalid) {
        return;
      }
      body = this.signupMForm.value;
      body.position = 4;
    }
    this.loading = true;
    this.commonService.registerUser(body).subscribe(
      (data) => {
        this.toastr.success(
          "Please check your email Junk folder for an iTASC link to finish registration and to set your password.",
          "Registration success",
          {
            timeOut: 100000,
          }
        );
        this.router.navigate(["/login"]);
      },
      (error) => {
        this.toastr.error("User not register.", "Register error");
        this.error = error;
        this.loading = false;
      }
    );
  }

  checkValidatEmail(e) {
    if (this.signupForm.controls["email"].status == "INVALID") {
      return;
    }
    if (e.target.value) {
      let email = e.target.value;
      this.userService.GetValidateEmail(email).subscribe((data: any) => {
        // this.dataSource = data.result;
        if (data) {
          if (data.result) {
            this.signupForm.controls["email"].setErrors({
              invalid: data.result,
            });
          }
        }
      });
    }
  }
}
