import { Component, OnInit,Input,EventEmitter ,Output} from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent {
  @Input() display: boolean;
  @Output() displayChange = new EventEmitter();
  @Output() confirmData = new EventEmitter();
  @Input() disbeBtn :boolean;
  onClose(){
  debugger
    this.displayChange.emit(false);
  }


  onConfirm(){
    debugger
      this.confirmData.emit(false);
    }

  // Work against memory leak if component is destroyed
  
  constructor() { }

  ngOnInit() {
  }

}
