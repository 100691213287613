import { Injectable } from '@angular/core';

export interface Menu {
   state: string;
   module_Name: string;
   type: string;
   icon: string;
   id: any;
}

const MENUITEMS = [
   {
      'state': 'active\r\n',
      'type': 'link\r\n',
      'module_Name': 'Active Cases',
      'icon': 'crop_7_5\r\n',
      'id': 1
   },
   {
      'state': 'grid\r\n',
      'type': 'link\r\n',
      'module_Name': 'Completed Cases',
      'icon': 'view_comfy\r\n',
      'id': 2
   },
   {
      'state': 'practice\r\n',
      'type': 'link\r\n',
      'module_Name': 'Mohs Practice List',
      'icon': 'view_list\r\n',
      'id': 3
   },
   {
      'state': 'patient\r\n',
      'type': 'link\r\n',
      'module_Name': 'Patient List',
      'icon': 'view_headline\r\n',
      'id': 4
   },
   {
      'state': 'practice-profile\r\n',
      'type': 'link\r\n',
      'module_Name': 'Practice Profile',
      'icon': 'view_headline\r\n',
      'id': 5
   },
   {
      'state': 'profile\r\n',
      'type': 'link\r\n',
      'module_Name': 'Your User Profile',
      'icon': 'view_headline\r\n',
      'id': 6
   },
   {
      'state': 'cpt-codes\r\n',
      'type': 'link\r\n',
      'module_Name': 'Cpt Codes',
      'icon': 'view_headline\r\n',
      'id': 6
   }
];

@Injectable()
export class MenuItems {
   getMenuitem(): Menu[] {
      return MENUITEMS;
   }
}
