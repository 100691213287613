import { NgModule } from "@angular/core";

import { MenuItems } from "./menu-items/menu-items";
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./accordion";
import { RouteTransformerDirective } from "./utils/RouteTransformerDirective";
import { DisclaimerComponent } from "./disclaimer/disclaimer.component";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    RouteTransformerDirective,
    AccordionDirective,
    DisclaimerComponent,
  ],
  imports: [DialogModule, ButtonModule],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    RouteTransformerDirective,
    AccordionDirective,
    DisclaimerComponent,
  ],
  providers: [MenuItems],
})
export class SharedModule {}
