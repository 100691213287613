import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styles: [`
  .footer{
    text-align: center;
    width:100%;
}
.footer p{
    color: #999999;	font-size: 12px;	line-height: 16px;padding-bottom: 17px;
}
.footer span{
    padding-left: 90px;
}p {
    font-family: Lato;
  }
  .breadcrumb {
      display: inline-block;
  }
  .breadcrumb:first-child:before {
      display: none;
  }
  .breadcrumb:before {
      content: '>';
      vertical-align: top;
      display: inline-block;
      margin: 0 10px 0 8px;
      color: #4A90E2  !important;
      -webkit-font-smoothing: antialiased;
  }
  .breadcrumb-item{
      min-width: 85px;
      padding: 0 8px;
      font-weight: 400;
      text-align: right;
      cursor: pointer;
  }
  .breadcrumb-item.active{
      font-weight: 500;
  }
  .bred-txt {
    text-decoration: underline !important;
    color: #4A90E2  !important;
  }

  `]
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  date: Date;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.date = new Date();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }
}
