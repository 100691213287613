import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

const baseUrl = environment.adminUrl + "/api";
const baseUrlLocal = environment.apiUrl + "/api";
@Injectable({
  providedIn: "root"
})
export class PatientsService {
  constructor(private http: HttpClient) {}

  // Get All Patient Details
  getAllPatient() {
    return this.http.get(`${baseUrl}/Patient/GetAllPatientDetails`).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Add Patient
  createPatient(data: any) {
    return this.http.post(`${baseUrl}/Patient/CreatePatient`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // search patient
  getPracticeListByData(value) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    const body = {
      PatientName: value
    };
    return this.http
      .post<any>(`${baseUrl}/Patient/GetFilterPatientName`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Update  Patient
  updatePatient(data) {
    return this.http.put(`${baseUrl}/Patient/PutPatient`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get search with id and name
  getMohsPracticeListByData(value) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    const body = {
      UserId: dataId.userid,
      Name: value
    };
    return this.http
      .post<any>(`${baseUrl}/Practice/GetSearchMohPractices`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
    return this.http
      .post<any>(`${baseUrl}/Practice/GetSearchMohPractices`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get all Patient list data
  getPatientListFilterData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatientDetailsById/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get all Patient list data
  getPatientListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetAllPatientDetails/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get all Patient list data
  getPatientDetailListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatient/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetPatientByLesion
  getPatientByLesionListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatientByLesion/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetReferralChecklist
  getPatientByChecklist(body) {
    return this.http.post(`${baseUrlLocal}/Patient/GetReferralChecklist`, body).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetMohsChecklist
  getPatientByMohsChecklist(body) {
    return this.http.post(`${baseUrl}/Patient/GetMohsChecklist`, body).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get Case Notes
  getCaseNotes(Id) {
    return this.http.get(`${baseUrl}/Case/GetCaseNotes/` + Id.Case_Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // http://192.168.2.60:200/api/Case/GetTumorsByPatient/1
  getPatientTumorData(Id) {
    return this.http.get(`${baseUrl}/Case/GetTumorsByPatient/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get all Dischargeplan List
  getDischargePlanList() {
    return this.http.get(`${baseUrl}/Patient/GetAllDischargePlan`).pipe(
      map(res => {
        return res;
      })
    );
  }


    // Get All cpt codes
    getAllcptcodes() {
      return this.http.get(`${baseUrl}/Patient/GetAllCptcodes`).pipe(
        map(res => {
          return res;
        })
      );
    }
    DeletePatient(data) {
      return this.http.get(`${baseUrl}/Patient/DeletePatientById/` + data).pipe(
        map(res => {
          return res;
        })
      );
    }
}
