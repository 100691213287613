import { Routes } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./component/login/login.component";
import { AdminloginComponent } from "./admin/login/adminlogin/adminlogin.component";
import { PasswordResetComponent } from "./admin/password-reset/password-reset.component";
import { ResetPasswordComponent } from "./component/password-reset/password-reset.component";
import {TwoFactorVerificationComponent} from './component/two-factor-verification/two-factor-verification.component';

import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./core/_helpers/auth.guard";
import { RegistrationComponent } from "./component/registration/registration.component";
import { LocateCentersComponent } from "./locate-centers/locate-centers.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FaqComponent } from "./faq/faq.component";
import { PotentialPatientsComponent } from "./potential-patients/potential-patients.component";

export const AppRoutes: Routes = [
  { path: "", component: HomeComponent, data: { breadcrumb: "Home" } },
  
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "/active",
        pathMatch: "full",
      },
      {
        path: "active",
        loadChildren: () =>
          import("./component/active-cases/active-cases.module").then(
            (m) => m.ActivecasesModule
          ),
      },
      {
        path: "practice-profile",
        loadChildren: () =>
          import("./component/practice-profile/practice-profile.module").then(
            (m) => m.PracticeProfileModule
          ),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./component/user-profile/user-profile.module").then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: "completed",
        loadChildren: () =>
          import("./component/completed-cases/completed-cases.module").then(
            (m) => m.CompletedCasesModule
          ),
      },
      {
        path: "practice",
        loadChildren: () =>
          import("./component/practice/practice.module").then(
            (m) => m.PracticeModule
          ),
        data: { breadcrumb: "Practice" },
      },
      {
        path: "patient",
        loadChildren: () =>
          import("./component/patient/patient.module").then(
            (m) => m.PatientModule
          ),
        data: { breadcrumb: "Patients" },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "otherCases",
        loadChildren: () =>
          import("./component/other-cases/other-cases.module").then(
            (m) => m.OtherCasesModule
          ),
        data: { breadcrumb: "OtherCases" },
      },

      {
        path: "cpt-codes",
        loadChildren: () =>
          import("./component/cpt-codes/cpt-codes.module").then(
            (m) => m.CptCodesModule
          ),
        // data: { breadcrumb: "" },
      },
    ],
  },

  {
    path: "login",
    component: LoginComponent,
    data: { breadcrumb: "Login" },
  },

  {
    path: "twoFactorVerification",
    component: TwoFactorVerificationComponent,
    data: { breadcrumb: "twoFactorVerification" },
  },

  {
    path: "registration",
    component: RegistrationComponent,
    data: { breadcrumb: "Register" },
  },

  {
    path: "admin/login",
    component: AdminloginComponent,
    data: { breadcrumb: "Login" },
  },

  {
    path: "admin/resetPassword",
    component: PasswordResetComponent,
    data: { breadcrumb: "Reset Password" },
  },

  {
    path: "resetPassword",
    component: ResetPasswordComponent,
    data: { breadcrumb: "Reset Password" },
  },

  {
    path: "locate-centers",
    component: LocateCentersComponent,
    data: { breadcrumb: "Locate Center" },
  },

  {
    path: "about-us",
    component: AboutUsComponent,
    data: { breadcrumb: "About Us" },
  },

  {
    path: "contact-us",
    component: ContactUsComponent,
    data: { breadcrumb: "Contact Us"},
  },

  {
    path: "faq",
    component: FaqComponent,
    data: { breadcrumb: "FAQ" },
  },

  {
    path: "potential-patients",
    component: PotentialPatientsComponent,
    data: { breadcrumb: "Potential Patients" },
  },
  
  {
    path: "admin",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "/user",
        pathMatch: "full",
      },
      {
        path: "UpdateEmailForAdmin",
        loadChildren: () =>
          import("./admin/update-email-for-admin/update-email-for-admin.module").then((m) => m.UpdateEmailForAdminModule),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./admin/user/user.module").then((m) => m.UserModule),
      },
      {
        path: "patient",
        loadChildren: () =>
          import("./admin/patient/patient.module").then((m) => m.PatientModule),
      },
      {
        path: "mohs-practice",
        loadChildren: () =>
          import("./admin/mohs-practice/mohs-practice.module").then(
            (m) => m.MohsPracticeModule
          ),
      },
      {
        path: "gen-practice",
        loadChildren: () =>
          import("./admin/general-practice/general-practice.module").then(
            (m) => m.GeneralPracticeModule
          ),
      },

      {
        path: "activeCase",
        loadChildren: () =>
          import("./admin/active-cases/active-cases.module").then(
            (m) => m.ActivecasesModule
          ),
      },
      {
        path: "dataAccess",
        loadChildren: () =>
          import("./admin/data-access-log/data-access-log.module").then(
            (m) => m.DataAccessLogModule
          ),
      },
      {
        path: "approval-practice",
        loadChildren: () =>
          import("./admin/pending-approvals/pending-approvals.module").then(
            (m) => m.PendingApprovalsModule
          ),
      },
      {
        path: "otherCases",
        loadChildren: () =>
          import("./admin/other-cases/other-cases.module").then(
            (m) => m.OtherCasesModule
          ),
      },
      {
        path: "completed",
        loadChildren: () =>
          import("./admin/completed-cases/completed-cases.module").then(
            (m) => m.CompletedCasesModule
          ),
      },
      {
        path: "user-acitvitylog",
        loadChildren: () =>
          import("./admin/user-activitylog/user-activitylog.module").then(
            (m) => m.UserActivitylogModule
          ),
      },

      {
        path: "cpt-codes",
        loadChildren: () =>
          import("./admin/cpt-codes-admin/cpt-codes-admin.module").then(
            (m) => m.CptCodesAdminModule
          ),
      },
    ],
  },
  { path: "home", redirectTo: "/", pathMatch: "full" },
];
