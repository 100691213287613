import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MustMatch } from "../../core/_helpers/must-match.validator";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../core/_adminServices";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.css"],
  providers: [ConfirmationService],
})
export class PasswordResetComponent implements OnInit {
  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  error = "";
  order: any;
  hide = true;
  confhide = true;
  display: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  openDisclaimer() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    } else {
      this.showDialog();
    }
  }

  showDialog() {
    debugger;
    this.display = true;
    // this.onSubmit(e);
  }

  onDialogClose(event) {
    debugger;
    this.display = event;
    this.submitted = false;
  }

  confirm1() {
    this.confirmationService.confirm({
      message:
        "I understand that iTASC is a software platform that attempts to facilitate patient referrals. iTASC is not responsible for management of the patient's medical needs or ensuring that treatment is completed. The responsibility for the patient remains with the physicians managing the patient, which includes the referring and accepting physicians.",
      header: "Disclaimer",
      icon: "pi pi-exclamation-triangle",
      accept: () => {},
      reject: () => {
        this.router.navigate(["/"]);
      },
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.order = params.id;
    });
    this.passwordForm = this.formBuilder.group(
      {
        confirmPassword: ["", Validators.required],
        password: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
   // this.confirm1();
  }

  get g() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;
    this.passwordForm.value["id"] = this.order;
    this.commonService.ResetPassword(this.passwordForm.value).subscribe(
      (data) => {
        //this.toastr.success(
        //   "User Registered Successfully",
        //   "Registration Success"
        // );
         this.toastr.success(
          "Password Has Been Saved",
          "Success"
         );
        this.router.navigate(["/login"]);
      },
      (error) => {
        this.toastr.error("User not register.", "Register error");
        this.error = error;
        this.loading = false;
      }
    );
  }
}
