import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";

import { environment } from "../config/environment";
import { User } from "../_models";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(UserName: string, Password: string): Observable<any> {
    debugger;
    const userData =
      "UserName=" + UserName + "&Password=" + Password + "&grant_type=password";
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, userData)
      .pipe(
        map((user) => {
          debugger
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        }), catchError(error => {
          debugger
          return throwError(error);
        })
      );
  }
  adminlogin(UserName: string, Password: string) {
    debugger
    const userData =
      "UserName=" + UserName + "&Password=" + Password + "&grant_type=password";
    return this.http
      .post<any>(`${environment.adminUrl}/authenticate`, userData)
      .pipe(
        map((user) => {
          debugger;
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }
  logout() {
    debugger;
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.currentUserSubject.next(null);
  }
}