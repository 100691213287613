import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";


const baseUrl = environment.adminUrl + "/api";

@Injectable({
  providedIn: "root"
})
export class CompleteCaseService {
  constructor(private http: HttpClient) {}

  // http://192.168.2.60:200/api/Case/GetCompletedCases

  // Get all ActiveCase list data
  getActiveCaseListData(Id) {
    return this.http.get(`${baseUrl}/Case/GetCompletedCases/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get All Action Details
  getAllAction() {
    return this.http.get(`${baseUrl}/Practice/GetAllStatus`).pipe(
      map(res => {
        return res;
      })
    );
  }
 // Get complated case By ID
  getComplateCaseByID(data: any) {
    return this.http.get(`${baseUrl}/Case/GetCompleteCaseById/` + data).pipe(
      map(res => {
        return res;
      })
    );
  }
   getDischargePlanList() {
    return this.http.get(`${baseUrl}/Patient/GetAllDischargePlan`).pipe(
      map(res => {
        return res;
      })
    );
  }
}
