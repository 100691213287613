import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

const baseUrl = environment.adminUrl + "/api";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) {}

  // Get All User Details
  getAllUser() {
    return this.http.get(`${baseUrl}/User/GetAllUsers`).pipe(
      map(res => {
        return res;
      })
    );
  }
  // http://192.168.2.60:260/api/user/CreateUser
  // Add Patient
  createPatient(data: any) {
    return this.http.post(`${baseUrl}/user/CreateUser`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // http://localhost:64164/api/Practice/CreateStaffByUser
  
  createPatientAndStaff(data: any) {
    return this.http.post(`${baseUrl}/user/CreateStaff`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get All staff Details
  getAllStaff() {
    return this.http.get(`${baseUrl}/Staff/GetAllStaffs`).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Get All Role Details
  getAllRole() {
    return this.http.get(`${baseUrl}/Role/GetAllRoles`).pipe(
      map(res => {
        return res;
      })
    );
  }
  // http://192.168.2.60:260/api/user/GetUser/2008
  // Get User by Id
  GetUserDataById(Id) {
    return this.http.get(`${baseUrl}/user/GetUser/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Update  User
  updateUser(data) {
    return this.http.put(`${baseUrl}/user/PutStaff`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

 //localhost:64164/api/User/IsEmailExits?Email=jbutt%40gmail.commmm
  // validate email address
  GetValidateEmail(email) {
    return this.http.get(`${baseUrl}/User/IsEmailExits?Email=` + email).pipe(
      map(res => {
        return res;
      })
    );
  }
//localhost:64164/api/Practice/GetPracticeByPracticeType/1
// Practice list based on practice name
GetPracticeList(Id) {
  return this.http.get(`${baseUrl}/Practice/GetPracticeByPracticeType/` + Id).pipe(
    map(res => {
      return res;
    })
  );
  
}
UpdateAdminEmail(userid,email) 
{
  return this.http.get(baseUrl+'/User/UpdateAdminEmail/?UserId=' + userid+'&Email='+email).pipe(
    map(res => {
      return res;
    })
  );
}
}
