import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from "rxjs/operators";

import { environment } from '../config/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }
    // Get All User Details
    getAllUser() {
        return this.http.get(`${environment.apiUrl}/api/User/GetAllUsers`).pipe(
            map(res => {
                return res;
            })
        );
    }
    // Get  User Details by id
    getAllUserbyId(id) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUser/${id}`).pipe(
            map(res => {
                return res;
            })
        );
    }
    // Get All User Details
    resendOtp(userId) {
        return this.http.post(`${environment.apiUrl}/api/User/ResendOtp?userId=${userId}`, null).pipe(
            map(res => {
                return res;
            })
        );
    }
    SendContactUsEmail(name,email,phoneNumber,message) {
        return this.http.post(`${environment.apiUrl}/api/User/SendContactUsEmail?name=${name}&email=${email}&phoneNumber=${phoneNumber}&message=${message}`, null).pipe(
            map(res => {
                return res;
            })
        );
    }
    // Compare OTP 
    verifieyOTP(userId, otp) {
        return this.http.post(`${environment.apiUrl}/api/User/VerifieyOTP?userId=${userId}&otp=${otp}`, null).pipe(
            map(res => {
                return res;
            })
        );
    }
}