export const environment = {
  // /*development */
  //  apiUrl: 'http://192.168.2.60:200',
  //  adminUrl:'http://192.168.2.60:260',

  // // /*Production */
  //  apiUrl: "http://localhost:8040",
  //  adminUrl: "http://localhost:8040",

   // /*Production */
   apiUrl: "https://api.itasc.org",
   adminUrl: "https://adminapi.itasc.org",

  /*DEV Production */
  // apiUrl: "https://devapi.itasc.org",
  // adminUrl: "https://devadminapi.itasc.org",


  // /*localhost */
  // apiUrl: "http://localhost:64164",
  // adminUrl: "http://localhost:64165",
  //adminUrl:"http://localhost:8070/",

  // adminUrl:'http://192.168.2.60:260'
};
