import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/api";
import { CommonService, PracticesService } from "../../core/_services";
import { Observable, forkJoin } from "rxjs";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../core/_adminServices";

@Component({
  selector: "app-model",
  templateUrl: "./model.component.html",
  styleUrls: ["./model.component.css"],
})
export class ModelComponent implements OnInit {
  yourProfileEditForm: FormGroup;
  yourPhysicianForm: FormGroup;
  submitted = false;
  loading = false;
  practiceType: any = [];
  geoTypes: any = [
    "establishment",
    "address",
    "locality",
    "health",
    "street_address",
    "street_address1",
    "street_address2",
    "administrative_area_level_1",
    "street_number",
    "sublocality",
    "administrative_area_level_3",
    "administrative_area_level_2",
    "administrative_area_level_4",
    "administrative_area_level_5",
    "archipelago",
    "colloquial_area",
    "continent",
    "country",
    "floor",
    "geocode",
    "intersection",
    "neighborhood",
    "plus_code",
    "point_o",
  ];
  stateList: any = [];
  positionList: any = [];
  physicianPosition: any = [];
  mapStateTemp: any = [];
  currentUser: any;
  error = "";
  sameAsAdmintrue: boolean = false;
  showPhysicianMenu: boolean = false;
  combinedMap: any;
  showHideAddLocation1: boolean = false;
  showHideAddLocation2: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private der: DynamicDialogConfig,
    private commonService: CommonService,
    private userService: UserService,
    private toastr: ToastrService // private messageService: MessageService
  ) {
    // this.sameAsAdmintrue = false;
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.yourProfileEditForm = this.formBuilder.group({
      name: new FormControl("", [
        Validators.required,
        // Validators.pattern("^[a-zA-Z -']+")
      ]),
      street_Address: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      state_Id: new FormControl("", Validators.required),
      zipCode: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      fax: new FormControl("", Validators.required),
      Staff_FName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      Staff_LName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      Staff_Email: new FormControl("", [Validators.required, Validators.email]),
      Staff_MobileNo: new FormControl("", Validators.required),
      Designation_Id: new FormControl("", Validators.required),
      max_Referral: new FormControl("", Validators.required),
      captcha: ["", Validators.required],
      street_Address1: new FormControl("", Validators.nullValidator),
      city1: new FormControl("", Validators.nullValidator),
      state_Id1: new FormControl("", Validators.nullValidator),
      zipCode1: new FormControl("", Validators.nullValidator),
      street_Address2: new FormControl("", Validators.nullValidator),
      city2: new FormControl("", Validators.nullValidator),
      state_Id2: new FormControl("", Validators.nullValidator),
      zipCode2: new FormControl("", Validators.nullValidator),
    });
    this.yourPhysicianForm = this.formBuilder.group({
      checkBox: ["", Validators.nullValidator],
      Physician_Fname: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      Physician_Lname: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      Phisician_Email: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      Phisician_MobileNo: new FormControl("", Validators.required),
      PhysicianDesignation_Id: new FormControl("", Validators.required),
    });

    this.getStateAndPosition();
    // this.sameAsAdmintrue = false;
  }

  get f() {
    return this.yourProfileEditForm.controls;
  }
  get g() {
    return this.yourPhysicianForm.controls;
  }

  getStateAndPosition() {
    debugger;
    const allStates = this.commonService.getAllStates();
    const allPosition = this.commonService.getAllPosition();
    forkJoin([allStates, allPosition]).subscribe((results: any) => {
      debugger;
      this.mapStateTemp = results[0].result;
      this.stateList = this.mapDropdropdownVlaue(results[0].result);
      this.positionList = this.mapDropdropdownpositionvalue(results[1].result);

      this.physicianPosition = this.positionList.filter(
        (item) => item.value != 2 && item.value != 3
      );
    });
  }

  mapDropdropdownVlaue(data): any {
    let dataArray = [];
    dataArray = data.map((element) => {
      const dataArray1 = {
        label: element.name,
        value: element.id,
      };
      return dataArray1;
    });
    return dataArray;
  }

  mapDropdropdownpositionvalue(data) {
    let dataArray = [];
    dataArray = data.map((element) => {
      const dataArray1 = {
        label: element.designation_Name,
        value: element.id,
      };
      return dataArray1;
    });
    return dataArray;
  }

  public userSettings: any = {
    showRecentSearch: false,
    showCurrentLocation: false,
    geoCountryRestriction: ["us"],
    geoTypes: this.geoTypes,
    inputPlaceholderText: "Street Address*",
    showSearchButton: false,
  };
  public componentData: any = "";
  autoCompleteCallback(data: any): any {
    debugger;
    if (data.response) {
      this.componentData = data.data.geometry.location;
      var location1 = ["street_Address", "city", "state_Id", "zipCode"];
      // this.practiceForm.get('street_Address').setValue(data.data.formatted_address);
      this.getAutoCompleteData(data.data.address_components, location1);
    } else {
      this.yourProfileEditForm.get("street_Address").setValue("");
      this.yourProfileEditForm.get("city").setValue("");
      this.yourProfileEditForm.get("state_Id").setValue("");
      this.yourProfileEditForm.get("zipCode").setValue("");
    }
    // if (data.results.length > 0) {

    // this.Hotel.Longitude = this.componentData.lng;
    // }
  }

  firstmethod(data: any): any {
    debugger;
    if (data.response) {
      this.componentData = data.data.geometry.location;
      var location2 = ["street_Address1", "city1", "state_Id1", "zipCode1"];
      // this.practiceForm.get('street_Address').setValue(data.data.formatted_address);
      this.getAutoCompleteData(data.data.address_components, location2);
    } else {
      this.yourProfileEditForm.get("street_Address1").setValue("");
      this.yourProfileEditForm.get("city1").setValue("");
      this.yourProfileEditForm.get("state_Id1").setValue("");
      this.yourProfileEditForm.get("zipCode1").setValue("");
    }
  }
  secondmethod(data: any): any {
    debugger;
    if (data.response) {
      this.componentData = data.data.geometry.location;
      var location3 = ["street_Address2", "city2", "state_Id2", "zipCode2"];
      // this.practiceForm.get('street_Address').setValue(data.data.formatted_address);
      this.getAutoCompleteData(data.data.address_components, location3);
    } else {
      this.yourProfileEditForm.get("street_Address2").setValue("");
      this.yourProfileEditForm.get("city2").setValue("");
      this.yourProfileEditForm.get("state_Id2").setValue("");
      this.yourProfileEditForm.get("zipCode2").setValue("");
    }
  }

  getAutoCompleteData(location, FormControl) {
    debugger;
    for (let i = 0; i < location.length; i++) {
      let type = location[i].types[0];
      if (location[i].types[0] == "subpremise") {
        var streetAddress2 =
          location[1].long_name + " " + location[2].long_name;
      } else {
        var streetAddress2 =
          location[0].long_name + " " + location[1].long_name;
      }

      if (type == "street_number") {
        this.yourProfileEditForm.get(FormControl[0]).setValue(streetAddress2);
      }
      if (type == "locality") {
        this.yourProfileEditForm
          .get(FormControl[1])
          .setValue(location[i].long_name);
      }
      if (type == "administrative_area_level_1") {
        let state_Id = this.mapStateTemp.filter((item) => {
          return item.stateCode == location[i].short_name;
        });

        this.yourProfileEditForm.get(FormControl[2]).setValue(state_Id[0].id);
      }
      if (type == "postal_code") {
        this.yourProfileEditForm
          .get(FormControl[3])
          .setValue(location[i].short_name);
      }
    }
  }

  SubmitChecklist(event) {
    debugger;
    if (event == true) {
      this.yourPhysicianForm.controls["Physician_Fname"].setValue(
        this.yourProfileEditForm.value.Staff_FName
      );
      this.yourPhysicianForm.controls["Physician_Lname"].setValue(
        this.yourProfileEditForm.value.Staff_LName
      );
      this.yourPhysicianForm.controls["Phisician_Email"].setValue(
        this.yourProfileEditForm.value.Staff_Email
      );
      this.yourPhysicianForm.controls["Phisician_MobileNo"].setValue(
        this.yourProfileEditForm.value.Staff_MobileNo
      );
      this.yourPhysicianForm.controls["PhysicianDesignation_Id"].setValue(
        this.yourProfileEditForm.value.Designation_Id
      );
      this.sameAsAdmintrue = true;
    }
    if (event == false) {
      this.sameAsAdmintrue = false;
    }
  }

  onselect(event) {
    debugger;

    if (event.value == 2 || event.value == 3) {
      this.showPhysicianMenu = true;
    } else {
      this.showPhysicianMenu = false;
    }
  }

  public addShowHide(value) {
    debugger;
    this.showHideAddLocation1 = value;
    if (this.showHideAddLocation1 == true) {
      this.yourProfileEditForm
        .get("street_Address1")
        .setValidators([Validators.required]);
      this.yourProfileEditForm
        .get("street_Address1")
        .setErrors({ required: true });

      this.yourProfileEditForm.get("city1").setValidators(Validators.required);

      this.yourProfileEditForm
        .get("state_Id1")
        .setValidators(Validators.required);
      this.yourProfileEditForm
        .get("zipCode1")
        .setValidators(Validators.required);
    }
    if (this.showHideAddLocation1 == false) {
      this.yourProfileEditForm
        .get("street_Address1")
        .setValidators(Validators.nullValidator);

      this.yourProfileEditForm
        .get("city1")
        .setValidators(Validators.nullValidator);

      this.yourProfileEditForm
        .get("state_Id1")
        .setValidators(Validators.nullValidator);
      this.yourProfileEditForm
        .get("zipCode1")
        .setValidators(Validators.nullValidator);

      this.yourProfileEditForm.controls.street_Address1.reset();
      this.yourProfileEditForm.controls.city1.reset();
      this.yourProfileEditForm.controls.state_Id1.reset();
      this.yourProfileEditForm.controls.zipCode1.reset();
    }
  }

  public addShowHidelocation(value) {
    this.showHideAddLocation2 = value;
    if (this.showHideAddLocation2 == true) {
      this.yourProfileEditForm
        .get("street_Address2")
        .setValidators(Validators.required);
      this.yourProfileEditForm
        .get("street_Address2")
        .setErrors({ required: true });
      this.yourProfileEditForm.get("city2").setValidators(Validators.required);

      this.yourProfileEditForm
        .get("state_Id2")
        .setValidators(Validators.required);
      this.yourProfileEditForm
        .get("zipCode2")
        .setValidators(Validators.required);
    }
    if (this.showHideAddLocation2 == false) {
      this.yourProfileEditForm
        .get("street_Address2")
        .setValidators(Validators.nullValidator);

      this.yourProfileEditForm
        .get("city2")
        .setValidators(Validators.nullValidator);

      this.yourProfileEditForm
        .get("state_Id2")
        .setValidators(Validators.nullValidator);
      this.yourProfileEditForm
        .get("zipCode2")
        .setValidators(Validators.nullValidator);
      this.yourProfileEditForm.controls.street_Address2.reset();
      this.yourProfileEditForm.controls.city2.reset();
      this.yourProfileEditForm.controls.state_Id2.reset();
      this.yourProfileEditForm.controls.zipCode2.reset();
    }
  }

  onSubmit() {
    debugger;

    this.submitted = true;
    // stop here if form is invalid
    // if (this.yourProfileEditForm.invalid) {
    //   return;
    // }
    if (
      (this.showPhysicianMenu == false && this.sameAsAdmintrue == false) ||
      (this.showPhysicianMenu == true && this.sameAsAdmintrue == true)
    ) {
      if (this.yourProfileEditForm.invalid) {
        return;
      } else {
        this.combinedMap = Object.assign(
          {},
          this.yourPhysicianForm.value,
          this.yourProfileEditForm.value
        );
      }
    }
    // if (this.showPhysicianMenu == true && this.sameAsAdmintrue == true) {
    //   if (this.yourProfileEditForm.invalid) {
    //     return;
    //   }
    // }
    if (this.showPhysicianMenu == true && this.sameAsAdmintrue == false) {
      if (this.yourProfileEditForm.invalid && this.yourPhysicianForm.invalid) {
        return;
      } else {
        this.combinedMap = Object.assign(
          {},
          this.yourPhysicianForm.value,
          this.yourProfileEditForm.value
        );
      }
    }
    if (this.showHideAddLocation1 == true) {
      if (
        this.yourProfileEditForm.value.street_Address ==
        this.yourProfileEditForm.value.street_Address1
      ) {
        this.toastr.error("Please enter unique Street Address");
        return;
      }
    }
    if (this.showHideAddLocation2 == true) {
      if (
        this.yourProfileEditForm.value.street_Address ==
        this.yourProfileEditForm.value.street_Address2
      ) {
        this.toastr.error("Please enter unique Street Address");
        return;
      } else if (
        this.yourProfileEditForm.value.street_Address1 ==
        this.yourProfileEditForm.value.street_Address2
      ) {
        this.toastr.error("Please enter unique Street Address");
        return;
      }
    }

    this.combinedMap["Latitude"] = this.componentData.lat;
    this.combinedMap["Longitude"] = this.componentData.lng;
    this.combinedMap["RoleId"] = 2;
    this.commonService
      .mohsSurgeonSignup(this.combinedMap)
      .pipe(first())
      .subscribe(
        (data: any) => {
          debugger;
          this.submitted = false;

          if (
            (this.showPhysicianMenu == false &&
              this.sameAsAdmintrue == false) ||
            (this.showPhysicianMenu == true && this.sameAsAdmintrue == true)
          ) {
            if (data) 
            {
              debugger;
              this.yourProfileEditForm.value["id"] = data.result.id;
              this.yourProfileEditForm.value["fullName"] = data.result.fullName;
              this.yourProfileEditForm.value["userName"] = data.result.userName;
              //commented by shakabe
              // this.commonService
              //   .sendMailRsvp(this.yourProfileEditForm.value)
              //   .pipe(first())
              //   .subscribe(
              //     (data) => {
              //       this.ref.close(true);
              //       this.yourProfileEditForm.reset();
              //     },
              //     (error) => {}
              //   );
              this.toastr.success(
                "Your request for the registration is sent to the admin for approval.",
                "Record Saved.",
                {
                  timeOut: 30000,
                }
              );
              this.ref.close(false);
              this.yourProfileEditForm.reset();
            }
          }
          if (this.showPhysicianMenu == true && this.sameAsAdmintrue == false) {
            if (data) {
              debugger;
              this.yourProfileEditForm.value["id"] = data.result.id;
              this.yourProfileEditForm.value["fullName"] = data.result.fullName;
              this.yourProfileEditForm.value["userName"] = data.result.userName;
              this.commonService
                .sendMailRsvp(this.yourProfileEditForm.value)
                .pipe(first())
                .subscribe(
                  (data) => {
                    // this.ref.close(true);
                    // this.yourProfileEditForm.reset();
                  },
                  (error) => {}
                );
            }

            let adminFormvalue = this.yourProfileEditForm.value;
            let physicianFormvalue = this.yourPhysicianForm.value;
            this.yourProfileEditForm.value["Latitude"] = this.componentData.lat;
            this.yourProfileEditForm.value[
              "Longitude"
            ] = this.componentData.lng;

            adminFormvalue.Staff_FName = physicianFormvalue.Physician_Fname;
            adminFormvalue.Staff_LName = physicianFormvalue.Physician_Lname;
            adminFormvalue.Staff_Email = physicianFormvalue.Phisician_Email;
            adminFormvalue.Staff_MobileNo =
              physicianFormvalue.Phisician_MobileNo;
            adminFormvalue.Designation_Id =
              physicianFormvalue.PhysicianDesignation_Id;
            debugger;
            this.commonService
              .mohsSurgeonSignup(adminFormvalue)
              .pipe(first())
              .subscribe((data: any) => {
                if (data) {
                  debugger;
                  this.yourProfileEditForm.value["id"] = data.result.id;
                  this.yourProfileEditForm.value["fullName"] =
                    data.result.fullName;
                  this.yourProfileEditForm.value["userName"] =
                    data.result.userName;
                  this.commonService
                    .sendMailRsvp(this.yourProfileEditForm.value)
                    .pipe(first())
                    .subscribe(
                      (data) => {
                        this.ref.close(true);
                        this.yourProfileEditForm.reset();
                      },
                      (error) => {}
                    );
                }
              });
          }

          this.addAllLocations(data);
          this.addMultipaleLocations(data);
        },
        (error) => {
          this.submitted = false;
          // this.submittedbtn = false;

          this.toastr.error(error, "Add Practice");
          this.error = error;
          this.loading = false;
        }
      );

    // this.commonService
    //   .sendMailRsvp(this.yourProfileEditForm.value)
    //   .pipe(first())
    //   .subscribe(
    //     (data) => {
    //       this.submitted = false;
    //       this.ref.close(true);
    //       this.yourProfileEditForm.reset();
    //     },
    //     (error) => {
    //       this.submitted = false;
    //       this.ref.close(false);
    //       this.yourProfileEditForm.reset();
    //     }
    //   );
  }

  addAllLocations(data) {
    debugger;
    if (this.showHideAddLocation1 == true) {
      var locationdata = {
        practiceId: data.result.staff.practice_Id,
        street_Address: this.yourProfileEditForm.value.street_Address1,
        city: this.yourProfileEditForm.value.city1,
        state_Id: this.yourProfileEditForm.value.state_Id1,
        zipCode: this.yourProfileEditForm.value.zipCode1,
      };
      this.commonService
        .addPracticeLocation(locationdata)
        .pipe(first())
        .subscribe(
          (data) => {
            // this.ref.close(true);
            this.yourProfileEditForm.reset();
          },
          (error) => {}
        );
    }
    if (this.showHideAddLocation2 == true) {
      var locationdata1 = {
        practiceId: data.result.staff.practice_Id,
        street_Address: this.yourProfileEditForm.value.street_Address2,
        city: this.yourProfileEditForm.value.city2,
        state_Id: this.yourProfileEditForm.value.state_Id2,
        zipCode: this.yourProfileEditForm.value.zipCode2,
      };
      this.commonService
        .addPracticeLocation(locationdata1)
        .pipe(first())
        .subscribe(
          (data) => {
            // this.ref.close(true);
            this.yourProfileEditForm.reset();
          },
          (error) => {}
        );
    }
  }

  addMultipaleLocations(data) {
    debugger;
    var locationdata1 = {
      practiceId: data.result.staff.practice_Id,
      street_Address: this.yourProfileEditForm.value.street_Address,
      city: this.yourProfileEditForm.value.city,
      state_Id: this.yourProfileEditForm.value.state_Id,
      zipCode: this.yourProfileEditForm.value.zipCode,
    };
    this.commonService
      .addPracticeLocation(locationdata1)
      .pipe(first())
      .subscribe(
        (data) => {
          // this.ref.close(true);
          this.yourProfileEditForm.reset();
        },
        (error) => {}
      );
  }

  onCancel() {
    this.submitted = false;
    this.ref.close();
    this.yourProfileEditForm.reset();
  }
  onReset() {
    this.submitted = false;
    this.yourProfileEditForm.reset();
  }

  showResponse(event) {
    debugger;
    console.log("captchaR", event);
    this.yourProfileEditForm.controls["captcha"].setValue("True");
    // this.messageService.add({
    //   severity: "info",
    //   summary: "Succees",
    //   detail: "User Responded",
    //   sticky: true,
    // });
  }
  expireResponse(event) {
    debugger;
    console.log("captchaE", event);
    this.yourProfileEditForm.controls["captcha"].setValue("");
  }

  checkValidatEmail(e) {
    debugger;
    if (this.yourProfileEditForm.controls["Staff_Email"].status == "INVALID") {
      return;
    }
    if (e.target.value) {
      let email = e.target.value;
      this.userService.GetValidateEmail(email).subscribe((data: any) => {
        debugger;

        if (data) {
          if (data.result) {
            this.yourProfileEditForm.controls["Staff_Email"].setErrors({
              invalid: data.result,
            });
            this.toastr.error(
              "The point of contact can be the same for the referring and accepting practice; however, a different email address must be used. Alternatively, a second point of contact can be used with separate email address."
            );
          }
        }
      });
    }
  }

  checkValidatEmailPhysician(e) {
    debugger;
    if (
      this.yourPhysicianForm.controls["Phisician_Email"].status == "INVALID"
    ) {
      return;
    }
    if (e.target.value) {
      let email = e.target.value;
      this.userService.GetValidateEmail(email).subscribe((data: any) => {
        debugger;

        if (data) {
          if (data.result) {
            this.yourPhysicianForm.controls["Phisician_Email"].setErrors({
              invalid: data.result,
            });
            this.toastr.error(
              "The point of contact can be the same for the referring and accepting practice; however, a different email address must be used. Alternatively, a second point of contact can be used with separate email address."
            );
          }
        }
      });
      if (this.showPhysicianMenu == true && this.sameAsAdmintrue == false) {
        if (
          this.yourProfileEditForm.value.Staff_Email ==
          this.yourPhysicianForm.value.Phisician_Email
        ) {
          this.yourPhysicianForm.controls["Phisician_Email"].setErrors({
            sameemail: "Please enter different email",
          });
        }
      }
    }
  }
}
