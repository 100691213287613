import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../config/environment";
import { map } from "rxjs/operators";

const baseUrl = environment.apiUrl + "/api";

@Injectable({
  providedIn: "root"
})
export class PatientsService {
  constructor(private http: HttpClient) {}
  // Add Patient
  createPatient(data: any) {
    return this.http.post(`${baseUrl}/Patient/CreatePatient`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get All Patient Details
  getAllPatient() {
    return this.http.get(`${baseUrl}/Patient/GetAllPatients`).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get search with id and name
  getMohsPracticeListByData(value) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    const body = {
      UserId: dataId.userid,
      Name: value
    };
    return this.http
      .post<any>(`${baseUrl}/Practice/GetSearchMohPractices`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
    return this.http
      .post<any>(`${baseUrl}/Practice/GetSearchMohPractices`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getPracticeListByData(value) {
    const body = {
      PatientName: value
    };
    return this.http
      .post<any>(`${baseUrl}/Patient/GetFilterPatientName`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Get all Patient list data
  getPatientListFilterData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatientDetailsById/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }
  // http://192.168.2.60:200/api/Case/GetTumorsByPatient/1
  getPatientTumorData(Id) {
    return this.http.get(`${baseUrl}/Case/GetTumorsByPatient/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }




  // Get all Patient list data
  getPatientListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetAllPatientDetails?id=` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get all Patient list data by practice Id
  getPatientListDataById(Id) {
    return this.http.get(`${baseUrl}/Patient/GetAllPatientDetailsUsingPracticeId?PracticeId=` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }






  // Get all Patient list data
  getPatientDetailListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatient/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetPatientByLesion
  getPatientByLesionListData(Id) {
    return this.http.get(`${baseUrl}/Patient/GetPatientByLesion/` + Id).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetPatientByLesion and practice
  getPatientByLesionandPracticeListData(Id) {
    const dataId = JSON.parse(localStorage.getItem("currentUser"));
    let body = {
      PatientId: Id,
      PracticeId: dataId.praticeid
    };
    return this.http.post(`${baseUrl}/Patient/GetCasesByPracticeAndPatient`, body).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetReferralChecklist
  getPatientByChecklist(body) {
    debugger;
    return this.http.post(`${baseUrl}/Patient/GetReferralChecklist`, body).pipe(
      map(res => {
        return res;
      })
    );
  }

  // GetMohsChecklist
  getPatientByMohsChecklist(body) {
    debugger;
    return this.http.post(`${baseUrl}/Patient/GetMohsChecklist`, body).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get all Dischargeplan List
  getDischargePlanList() {
    return this.http.get(`${baseUrl}/Patient/GetAllDischargePlan`).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Update  Patient
  updatePatient(data) {
    return this.http.put(`${baseUrl}/Patient/PutPatient`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Get Case Notes
  getCaseNotes(Id) {
    debugger;
    return this.http.get(`${baseUrl}/Case/GetCaseNotes/` + Id.Case_Id).pipe(
      map(res => {
        return res;
      })
    );
  }



    // Get all cpt code data by practice Id
    getCptcodes(Id) {
      return this.http.get(`${baseUrl}/Patient/GetCptCodes?PracticeId=` + Id).pipe(
        map(res => {
          return res;
        })
      );
    }

    getCptcodesById(Id) {
      return this.http.get(`${baseUrl}/Patient/GetAllCptCodesUsingPracticeId?PracticeId=` + Id).pipe(
        map(res => {
          return res;
        })
      );
    }

}
