import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, CommonService } from '../../../core/_services';
@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  hide = true;
  confhide = true;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public commonService: CommonService,
    private authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    debugger;
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin/activeCase';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService
      .adminlogin(this.f.get('email').value, this.f.get('password').value)
      .pipe(first())
      .subscribe(
        data => {
          debugger;
          this.commonService.getAllMenu(data.roleid).subscribe(
            (menuData: any) => {
              if (menuData) {
                this.toastr.success(
                  'You are logged in successfully',
                  'Login success'
                );
                const menuItems = menuData.result.map(item => item.module);
                localStorage.setItem('menuItems', JSON.stringify(menuItems));
                  this.returnUrl =
                    this.route.snapshot.queryParams['returnUrl'] ||
                    '/admin/activeCase';
                this.router.navigate([this.returnUrl]);
              }
            },
            err => {
              this.loading = false;
            }
          );
        },
        error => {
          this.toastr.error('Email or password is incorrect', 'login error');
          this.error = error;
          this.loading = false;
        }
      );
  }
}
